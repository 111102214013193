var render = function () {
  var _vm$method;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card mt-2 px-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('i', {
    staticClass: "fa fa-line-chart mr-2"
  }), _c('router-link', {
    staticClass: "title",
    attrs: {
      "to": {
        path: '/monitoring/log'
      }
    }
  }, [_vm._v(" Log Activity")]), _vm._m(0), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Detail Activity")]), _c('h4', {
    staticClass: "pt-1 mt-1"
  }, [_vm._v("Detail Log Activity")])], 1)]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("User ID")]), _c('p', [_vm._v(_vm._s(_vm.userId))])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("Email")]), _c('p', [_vm._v(_vm._s(_vm.email))])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("Data URL")]), _c('p', [_vm._v(_vm._s(_vm.dataUrl))])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("Data Request")]), _c('div', {
    staticClass: "card p-2",
    staticStyle: {
      "max-height": "300px"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.dataRequest))])])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("Data Response")]), _c('div', {
    staticClass: "card p-2",
    staticStyle: {
      "max-height": "300px"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.dataResponse))])])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("Host Client")]), _c('p', [_vm._v(_vm._s(_vm.hostClient))])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("Method")]), _c('p', [_vm._v(_vm._s((_vm$method = _vm.method) === null || _vm$method === void 0 ? void 0 : _vm$method.toUpperCase()))])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('h5', [_vm._v("Created At")]), _c('p', [_vm._v(_vm._s(_vm.formatDate(_vm.created)))])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "mx-3"
  }, [_c('i', {
    staticClass: "fa fa-chevron-right"
  })]);
}]

export { render, staticRenderFns }