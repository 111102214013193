<template>
  <div class="row mt-2">
    <div class="col-md-12">
      <div class="card mt-2 px-2">
        <div class="card-body">
          <i class="fa fa-line-chart mr-2"></i>
          <router-link :to="{ path: '/monitoring/log' }" class="title">
            Log Activity</router-link
          >
          <span class="mx-3"><i class="fa fa-chevron-right"></i></span>
          <span class="font-weight-bold">Detail Activity</span>
          <h4 class="pt-1 mt-1">Detail Log Activity</h4>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="mb-4">
            <h5>User ID</h5>
            <p>{{ userId }}</p>
          </div>
          <div class="mb-4">
            <h5>Email</h5>
            <p>{{ email }}</p>
          </div>
          <div class="mb-4">
            <h5>Data URL</h5>
            <p>{{ dataUrl }}</p>
          </div>
          <div class="mb-4">
            <h5>Data Request</h5>
            <div class="card p-2" style="max-height: 300px">
              <pre>{{ dataRequest }}</pre>
            </div>
          </div>
          <div class="mb-4">
            <h5>Data Response</h5>
            <div class="card p-2" style="max-height: 300px">
              <pre>{{ dataResponse }}</pre>
            </div>
          </div>
          <div class="mb-4">
            <h5>Host Client</h5>
            <p>{{ hostClient }}</p>
          </div>
          <div class="mb-4">
            <h5>Method</h5>
            <p>{{ method?.toUpperCase() }}</p>
          </div>
          <div class="mb-4">
            <h5>Created At</h5>
            <p>{{ formatDate(created) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DetailLogMonitoring',
  data() {
    return {
      userId: null,
      email: null,
      dataUrl: null,
      dataRequest: null,
      dataResponse: null,
      hostClient: null,
      method: null,
      created: null,
    };
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.setLogData();
    },
    ErrorMessage: function() {
      if (!this.ErrorMessage) return;
      this.alertMsg(this.ErrorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Detail Log Monitoring | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.log.isLoading,
      isError: (state) => state.log.isError,
      ErrorMessage: (state) => state.log.ErrorMessage,
      item: (state) => state.log.item,
    }),
  },
  created: function() {
    const id = this.$route.params.id;
    this.getLogById(id);
  },
  methods: {
    ...mapActions('log', ['fetchLogById']),

    getLogById(id) {
      this.fetchLogById({ id });
    },

    setLogData() {
      let data = this.item;
      this.userId = data?.user_id;
      this.email = data?.admin_cms?.email;
      this.dataUrl = data?.data_url;
      this.dataRequest = data?.data_request;
      this.dataResponse = JSON.parse(data?.data_response);
      this.hostClient = data?.host_client;
      this.method = data?.method;
      this.created = data?.created;
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
<style scoped>
.title {
  color: black;
  text-decoration: none;
}
.title:hover {
  color: black;
}
</style>
